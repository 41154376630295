export const EXIT_POPUP_TEXT = {
    en: {
        TITLE: '"I want to hug the people that created this."',
        SUBTITLE: "- Emma J Coulson, with monday.com since 2014",
        SIGNUP_HEADER_TEXT: "Join 15,000+ teams who are passionate about monday.com",
        SIGNUP_INPUT_PLACEHOLDER: "Enter your work email",
        SIGNUP_BUTTON_TEXT: "Get free access now",
        SIGNUP_FOOTER_TEXT: "No thanks"
    },
    fr: {
        TITLE: "Vous n'avez pas le temps... Nous comprenons :)",
        SUBTITLE: "Envoyez-vous une invitation maintenant, et débutez quand vous avez 3 minutes",
        SIGNUP_INPUT_PLACEHOLDER: "Entrez votre adresse e-mail",
        SIGNUP_BUTTON_TEXT: "m'inviter"
    },
    es: {
        TITLE: "Estás ocupado... Te entendemos :)",
        SUBTITLE: "Envíate una invitación ahora y prueba monday.com cuando tengas 3 minutos",
        SIGNUP_INPUT_PLACEHOLDER: "Ingresa tu correo laboral",
        SIGNUP_BUTTON_TEXT: "Enviar"
    },
    de: {
        TITLE: "Du bist beschäftigt… wir verstehen das :)",
        SUBTITLE: "Sende dir jetzt selbst eine Einladung und probiere uns aus, wenn 3 Minuten Zeit hast",
        SIGNUP_INPUT_PLACEHOLDER: "Ihre Firmen-E-Mail-Adresse",
        SIGNUP_BUTTON_TEXT: "Mich selbst einladen"
    },
    it: {
        TITLE: "Sei impegnato... ti capiamo :)",
        SUBTITLE: "Mandati un auto-invito, e mettici alla prova quando hai 3 minuti liberi",
        SIGNUP_INPUT_PLACEHOLDER: "Inserisci la tua email aziendale",
        SIGNUP_BUTTON_TEXT: "Invita Me Stesso"
    }
};
export const EXIT_POPUP_ORIGIN = "hp_exit_intent_modal";

import styled from "@emotion/styled";
import colors from "/styles/colors";
import { MODAL_BACKGROUND } from "constants/z-index";
export const StyledModalBackgroundComponent = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${colors["black"]};
  opacity: 0.9;
  top: 0;
  left: 0;
  z-index: ${MODAL_BACKGROUND};
`;
